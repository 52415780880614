import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

interface ThemeProviderProps {
  children: ReactNode;
}

interface ThemeContextProps {
  darkModeEnabled: boolean;
  toggleDarkMode: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const getInitialDarkMode = () => {
    if (typeof window !== 'undefined') {
      const darkMode = window.localStorage.getItem('darkModeEnabled');
      if (darkMode !== null) {
        return JSON.parse(darkMode);
      }
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return false;
  };

  const [darkModeEnabled, setDarkModeEnabled] =
    useState<boolean>(getInitialDarkMode);

  useEffect(() => {
    const handleChange = (e: MediaQueryListEvent) => {
      setDarkModeEnabled(e.matches);
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    if (darkModeEnabled) {
      document.querySelector('body')?.classList.add('dark');
    } else {
      document.querySelector('body')?.classList.remove('dark');
    }
  }, [darkModeEnabled]);

  const toggleDarkMode = () => {
    window.localStorage.setItem(
      'darkModeEnabled',
      JSON.stringify(!darkModeEnabled),
    );
    setDarkModeEnabled(!darkModeEnabled);
  };

  return (
    <ThemeContext.Provider
      value={{
        darkModeEnabled,
        toggleDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};
