import '@fontsource/dm-sans';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/dm-mono';
import '@fontsource/dm-mono/500.css';
import React from 'react';
import { ThemeProvider } from './src/providers/ThemeProvider';

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};
